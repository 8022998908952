import React, { useCallback, useEffect, useState } from 'react';

const TotalItem = ({ left, right, fontBold = false, negative = false }) => (
  <div className="order-review-total-list">
    <div
      className="order-review-total-list-left"
      style={fontBold ? { fontWeight: 700 } : null}>
      {left}:
    </div>
    <div
      className="order-review-total-list-right"
      style={fontBold ? { fontWeight: 700 } : null}>
      {negative ? '-' : ''}
      {right?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })}
    </div>
  </div>
);

const TotalAmount = ({ order }) => {
  const [orderTotal, setOrderTotal] = useState(0);
  const [totalAssemblyFee, setTotalAssemblyFee] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [amountBeforeTax, setAmountBeforeTax] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [amountDue, setAmountDue] = useState(0);
  const [additionalAmount, setAdditionalAmount] = useState(0);
  const [taxes, setTaxes] = useState([]);

  const totalCalculation = useCallback(() => {
    if (order) {
      const totalFinishedEnd = order?.orderitemfinishedends?.reduce(
        (acc, item) => acc + item.quantity * item.price,
        0,
      );

      const totalAdditionalAmount =
        order?.orderadditionalcharges?.reduce(
          (acc, item) => acc + item.amount,
          0,
        ) || 0;
      setAdditionalAmount(totalAdditionalAmount);

      const totalFinishedEndBase = order?.orderitemfinishedends?.reduce(
        (acc, item) => acc + item.quantity * item.basePrice,
        0,
      );

      const totalOrderItem =
        order?.orderstyles?.reduce((acc, style) => {
          const styleTotal =
            style?.orderitems?.reduce((subtotal, item) => {
              const itemQuantity = item?.quantity || 0;
              const itemPrice = item?.price || 0;
              return subtotal + itemQuantity * itemPrice;
            }, 0) || 0;
          return acc + styleTotal;
        }, 0) || 0;

      const totalOrderItemBase =
        order?.orderstyles?.reduce((acc, style) => {
          const styleTotal =
            style?.orderitems?.reduce((subtotal, item) => {
              const itemQuantity = item?.quantity || 0;
              const itemPrice = item?.basePrice || 0;
              return subtotal + itemQuantity * itemPrice;
            }, 0) || 0;
          return acc + styleTotal;
        }, 0) || 0;

      const totalOrderAssemblyFee =
        order?.orderstyles?.reduce((acc, style) => {
          const styleTotal =
            style?.orderitems?.reduce((subtotal, item) => {
              const itemQuantity = item?.quantity || 0;
              const itemPrice = item?.assemblyRequest
                ? item?.assemblyFee || 0
                : 0;
              return subtotal + itemQuantity * itemPrice;
            }, 0) || 0;
          return acc + styleTotal;
        }, 0) || 0;

      setTotalAssemblyFee(totalOrderAssemblyFee);
      const orderTotalBase = totalFinishedEndBase + totalOrderItemBase;
      const orderTotalCurrent = totalFinishedEnd + totalOrderItem;
      setOrderTotal(orderTotalBase);

      const totalAdjusted = orderTotalCurrent - orderTotalBase;
      setDiscountAmount(totalAdjusted);

      let discountPercentageTotal = 0;
      if (order.discountPercentage) {
        discountPercentageTotal = parseFloat(
          ((order.discountPercentage / 100) * orderTotalCurrent).toFixed(2),
          10,
        );
        setDiscountPercentage(discountPercentageTotal);
      } else {
        setDiscountPercentage(0);
      }
      const subTotal =
        orderTotalCurrent -
        discountPercentageTotal +
        totalOrderAssemblyFee +
        order.deliveryCost +
        totalAdditionalAmount;

      setAmountBeforeTax(subTotal);

      let taxTotal = 0;
      const taxItem = order?.ordertaxs?.map(tax => ({
        label: tax.taxLabel,
        taxRate: tax.taxRate,
        taxFee: (tax.taxRate / 100) * subTotal,
      }));
      setTaxes(taxItem);
      if (taxItem?.length > 0) {
        taxTotal = taxItem.reduce((acc, item) => acc + item.taxFee, 0);
      }
      const totalAmount = subTotal + taxTotal;
      setAmountTotal(totalAmount);
      let totalPaid = 0;
      if (order?.orderpayments?.length > 0) {
        totalPaid = order.orderpayments.reduce(
          (acc, payment) => acc + parseFloat(payment.amount),
          0,
        );
      }
      setAmountPaid(totalPaid);
      const totalDue = totalAmount - totalPaid;
      setAmountDue(totalDue);
    }
  }, [order]);

  useEffect(() => {
    totalCalculation();
  }, [totalCalculation]);

  return (
    <div>
      <div className="order-review-total-box">
        <TotalItem left="Cabinets & Parts" right={orderTotal} />
      </div>
      {discountAmount ? (
        <div className="order-review-total-box">
          <TotalItem
            left="Amount Adjustment"
            right={Math.abs(discountAmount)}
            negative={discountAmount < 0}
          />
        </div>
      ) : null}
      {discountPercentage ? (
        <div className="order-review-total-box">
          <TotalItem
            left={`${order?.discountPercentage}% discount`}
            right={discountPercentage}
            negative
          />
        </div>
      ) : null}
      {totalAssemblyFee ? (
        <div className="order-review-total-box">
          <TotalItem left="Total Assembly Fee" right={totalAssemblyFee} />
        </div>
      ) : null}
      {order?.deliveryCost ? (
        <div className="order-review-total-box">
          <TotalItem left="Delivery Cost" right={order?.deliveryCost} />
        </div>
      ) : null}
      {additionalAmount ? (
        <div className="order-review-total-box">
          <TotalItem left="Additional Cost" right={additionalAmount} />
        </div>
      ) : null}
      {amountBeforeTax ? (
        <div className="order-review-total-box">
          <TotalItem left="Amount before Tax" right={amountBeforeTax} />
        </div>
      ) : null}
      {taxes?.length > 0 && (
        <div>
          {taxes.map(tax => (
            <div className="order-review-total-box" key={Math.random()}>
              <TotalItem
                left={`${tax?.label}(${tax?.taxRate}%)`}
                right={tax?.taxFee}
              />
            </div>
          ))}
        </div>
      )}

      <div className="order-review-total-box">
        <TotalItem fontBold left="Total Amount" right={amountTotal} />
      </div>
      {order?.invoiceNumber && (
        <>
          <div className="order-review-total-box">
            <TotalItem
              fontBold
              negative
              left="Amount Paid"
              right={amountPaid}
            />
          </div>
          <div className="order-review-total-box">
            <TotalItem fontBold left="Amount Due" right={amountDue} />
          </div>
        </>
      )}
    </div>
  );
};

export default TotalAmount;
