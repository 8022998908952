/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppForm from '../components/form/AppForm';
import AppInput from '../components/form/AppInput';
import SubmitButton from '../components/form/SubmitButton';
import uploadCloudinary from '../components/cloudinary/uploadCloudinary';
import getErrors from '../store/actions/errorActions';
import request from '../config/request';
import AppSelect from '../components/form/AppSelect';
import DragDrop from '../components/cloudinary/DragDrop';
import selectList from '../constants/selectList.json';
import Layout from '../components/Layout';

const startYearList = totalYear => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < totalYear; i += 1) {
    years.push(currentYear - i);
  }

  return years;
};

const validationSchema = Yup.object().shape({
  businessNumber: Yup.string()
    .required()
    .min(2)
    .max(80)
    .label('Business Number'),
  firstName: Yup.string().required().min(2).max(50).label('First Name'),
  lastName: Yup.string().required().min(2).max(50).label('Last Name'),
  address: Yup.string().required().max(200).label('Address'),
  addressLine2: Yup.string().max(100).label('Apt or Suit #'),
  city: Yup.string().required().max(40).label('City'),
  province: Yup.string().required().max(80).label('Province'),
  postCode: Yup.string().required().max(40).label('Postal Code'),
  country: Yup.string().required().max(40).label('Country'),
  phone: Yup.string().required().min(8).max(20).label('Phone'),
  fax: Yup.string().min(8).max(20).label('Fax'),
  company: Yup.string().required().min(2).max(80).label('Company'),
  website: Yup.string().max(180).label('Website'),
  startupYear: Yup.string().required().max(180).label('Start up year'),
  businessType: Yup.string().required().max(180).label('Business Type'),
  occupation: Yup.string().required().max(40).label('Occupation'),
  joinSource: Yup.string().max(40).label('Join Source'),
  businessLicense: Yup.string().max(250).label('Business License'),
});

const CustomerPicture = ({ title, url }) => {
  const pathIndex = url.lastIndexOf('.');
  const fileType = url.substr(pathIndex + 1).toLowerCase();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '200px' }}>{title}</TableCell>
            <TableCell>
              <a href={`${url}`} target="_black">
                {fileType === 'pdf' ? (
                  <img
                    src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1615948739/samples/pdf_nw6xuu.png"
                    alt="pdf"
                    width="100px"
                  />
                ) : (
                  <img
                    src={url}
                    alt="customer pic"
                    className="display-contact-pic"
                    onError={({ target }) => {
                      const img = target;
                      img.src =
                        'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png';
                      img.onerror = null;
                    }}
                  />
                )}
              </a>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function EditProfileForm({ data, userToken }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [businessLicense, setBusinessLicense] = useState(null);

  return (
    <Layout>
      <Container>
        <h1 className="signin-header">Update Profile</h1>
        <Paper max-width="md" className="signin-paper">
          <AppForm
            initialValues={{
              businessNumber: data?.businessNumber ?? '',
              firstName: data?.firstName ?? '',
              lastName: data?.lastName ?? '',
              address: data?.address ?? '',
              addressLine2: data?.addressLine2 ?? '',
              city: data?.city ?? '',
              province: data?.province ?? '',
              postCode: data?.postCode ?? '',
              country: data?.country ?? '',
              phone: data?.phone ?? '',
              fax: data?.fax ?? '',
              company: data?.company ?? '',
              website: data?.website ?? '',
              startupYear: data?.startupYear ?? '',
              businessType: data?.businessType ?? '',
              occupation: data?.occupation ?? '',
              joinSource: data?.joinSource ?? '',
              businessLicense: data?.businessLicense ?? '',
            }}
            onSubmit={async values => {
              const updatedValues = { ...values };

              if (businessLicense) {
                const businessLicenseLink = await uploadCloudinary(
                  businessLicense,
                  dispatch,
                );
                updatedValues.businessLicense = businessLicenseLink;
              }

              try {
                const res = await request.put(
                  `customer/update/${data.id}`,
                  updatedValues,
                  {
                    headers: { Authorization: `Bearer ${userToken}` },
                  },
                );
                if (res.data.code === 201) {
                  navigate(`/`);
                }
              } catch (e) {
                let displayError = 'Update profile failed';
                if (e?.response?.data?.msg) {
                  displayError = e?.response?.data?.msg;
                }
                dispatch(getErrors(displayError));
              }
            }}
            validationSchema={validationSchema}>
            <h2 className="sigin-h2">Your Company Info</h2>
            <AppInput
              name="company"
              label="Company"
              type="text"
              required
              inputprops={{ maxLength: 100 }}
            />
            <AppInput
              name="businessNumber"
              label="Business Number"
              type="text"
              required
              inputprops={{ maxLength: 80 }}
            />
            <div className="sigin-name">
              <div className="sigin-name-item">
                <AppInput
                  name="firstName"
                  label="Owners First Name"
                  type="text"
                  inputprops={{ maxLength: 50 }}
                  required
                />
              </div>
              <div className="sigin-name-item">
                <AppInput
                  name="lastName"
                  label="Owners Last Name"
                  type="text"
                  inputprops={{ maxLength: 50 }}
                  required
                />
              </div>
            </div>
            <AppInput
              name="address"
              label="Address"
              type="text"
              required
              inputprops={{ maxLength: 200 }}
            />
            <AppInput
              name="addressLine2"
              label="Apt or Suit #"
              type="text"
              inputprops={{ maxLength: 200 }}
            />
            <AppInput
              name="city"
              label="City"
              type="text"
              required
              inputprops={{ maxLength: 40 }}
            />
            <AppInput
              name="province"
              label="Province"
              type="text"
              required
              inputprops={{ maxLength: 80 }}
            />
            <AppInput
              name="postCode"
              label="Postal Code"
              type="text"
              required
              inputprops={{ maxLength: 40 }}
            />
            <AppSelect
              name="country"
              label="Country"
              required
              options={selectList.country.list}
            />

            <AppInput
              name="phone"
              label="Phone"
              type="text"
              required
              inputprops={{ maxLength: 20 }}
            />
            <AppInput
              name="fax"
              label="Fax"
              type="text"
              inputprops={{ maxLength: 30 }}
            />
            <AppInput
              name="website"
              label="Website"
              type="text"
              inputprops={{ maxLength: 200 }}
            />
            <Divider />

            <h2 className="sigin-h2">Company Details</h2>
            <AppSelect
              name="startupYear"
              label="Business Startup Year"
              required
              options={startYearList(30)}
            />
            <AppSelect
              name="businessType"
              label="Type of Business"
              required
              options={selectList.businessType.list}
            />
            <AppSelect
              name="occupation"
              label="I am a/an"
              required
              options={selectList.occupation.list}
            />
            <DragDrop
              file={businessLicense}
              setFile={setBusinessLicense}
              label="Upload a copy of your Business License"
            />
            <CustomerPicture
              title="Business License"
              url={data.businessLicense}
            />
            <Divider />
            <h2 className="sigin-h2">How did you hear about us?</h2>
            <AppInput
              name="joinSource"
              label="If you were referred by a person with description OR with our Sales Rep."
              type="text"
              inputprops={{ maxLength: 200 }}
            />

            <SubmitButton title="Update" />
          </AppForm>
        </Paper>
      </Container>
    </Layout>
  );
}

function EditProfile() {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const { userToken } = useSelector(state => state.auth);
  const fetchData = useCallback(async () => {
    try {
      const res = await request.get('customer/customerlistone', {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setData(res.data.data);
    } catch (e) {
      let displayError = 'Create user failed';
      if (e?.response?.data?.msg) {
        displayError = e?.response?.data?.msg;
      }
      dispatch(getErrors(displayError));
    }
  }, [userToken, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return data ? <EditProfileForm data={data} userToken={userToken} /> : null;
}

export default EditProfile;
