// import { Mail, Notifications } from '@mui/icons-material'
import {
  AppBar,
  Avatar,
  Box,
  Dialog,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RiLockPasswordLine } from 'react-icons/ri';
import { MdLogout } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../store/actions/authActions';
import UpdatePassword from './UpdatePassword';
import logoList, { titleList } from '../constants/logoList';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth';

const Navbar = () => {
  const { userInfo } = useSelector(state => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updatePasswordOpen, setUpdatePasswordOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <AppBar position="sticky">
      <Dialog
        open={updatePasswordOpen}
        onClose={() => setUpdatePasswordOpen(false)}>
        <UpdatePassword setUpdatePasswordOpen={setUpdatePasswordOpen} />
      </Dialog>
      <StyledToolbar>
        <div className="navbar-left">
          <img
            src={logoList?.[CLIENT]?.NAV_LOGO}
            alt="logo"
            className="navbar-logo"
          />
          <h3>{titleList?.[CLIENT]?.TITLE}</h3>
        </div>
        <Box>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}>
              <Avatar sx={{ width: 35, height: 35 }}>
                {userInfo?.userFullName
                  ?.split(' ')
                  .map(name => name?.[0]?.toUpperCase())
                  ?.join('')}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <MenuItem onClick={() => navigate('/editprofile')}>
            <ListItemIcon>
              <CgProfile size={24} />
            </ListItemIcon>
            Change Profile
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>
            <Avatar /> My account
          </MenuItem> */}
          <MenuItem onClick={() => setUpdatePasswordOpen(true)}>
            <ListItemIcon>
              <RiLockPasswordLine size={24} />
            </ListItemIcon>
            Update Password
          </MenuItem>
          <Divider />

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <MdLogout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar;
